<template>
  <div class="payment">
    <div class="container">
      <h1 class="title">{{ productInfo.productName }}</h1>
      <h2 class="count">
        {{ chargeFee
        }}<span class="unit">{{ $tc('common.unit', chargeFee) }}</span>
      </h2>
      <p class="desc">
        {{ $tc('common.total', productInfo.price) }}{{ $t('payment.unit') }}
      </p>
      <template v-if="totalMiBalance > 0">
        <div class="mibi-list">
          <div class="mibi-pay">
            <div class="left">
              <em class="mibi-icon"></em>
              <div class="title-count">
                <p class="title">{{ $t('common.mibi') }}</p>
                <p class="count-mibi">
                  {{ $tc('common.accuout', totalMiBalance) }}
                </p>
              </div>
            </div>
            <div class="right">
              {{ $tc('common.accuout', 0 - deductAmount) }}
              <em
                class="select-icon"
                :class="{ selected: selectMibi }"
                @click="selectMibi = !selectMibi"
              ></em>
            </div>
          </div>
          <template v-if="visibleCardList">
            <div :class="['discount-main', { 'active-discount': showAll }]">
              <div class="discount-list">
                <ul class="list">
                  <!-- 主题卡券 -->
                  <li
                    class="discount-item"
                    v-if="showUseMarketCard"
                    @click="selectCard('selectMarketCard')"
                  >
                    <div class="left">
                      <h2 class="title">
                        {{ productInfo.partnerGiftcardName }}
                      </h2>
                      <span>{{ $t('payment.priorityUse') }}</span>
                    </div>
                    <div class="right">
                      {{
                        $tc(
                          'common.accuout',
                          productInfo.marketGiftcardAvailableValue
                        )
                      }}
                      <em
                        class="select-icon"
                        :class="{ selected: productInfo.selectMarketCard }"
                      ></em>
                    </div>
                  </li>
                  <!-- 通用卡券 -->
                  <li
                    class="discount-item"
                    v-if="showUseGiftcard"
                    @click="selectCard('selectGiftCard')"
                  >
                    <div class="left">
                      <h2 class="title">通用礼券</h2>
                    </div>
                    <div class="right">
                      {{
                        $tc(
                          'common.accuout',
                          productInfo.giftcardAvailableValue
                        )
                      }}
                      <em
                        class="select-icon"
                        :class="{ selected: productInfo.selectGiftCard }"
                      ></em>
                    </div>
                  </li>
                  <!-- 余额 -->
                  <li
                    class="discount-item"
                    @click="selectCard('selectMibiBalance')"
                  >
                    <div class="left">
                      <h2 class="title">{{ $t('common.balance') }}</h2>
                    </div>
                    <div class="right">
                      {{ $tc('common.accuout', productInfo.mibiBalance) }}
                      <em
                        class="select-icon"
                        :class="{ selected: productInfo.selectMibiBalance }"
                      ></em>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="arrow" @click="showAll = !showAll">
              <em :class="['arrow-icon', { active: showAll }]"></em>
            </div>
          </template>
        </div>
        <p class="line"></p>
      </template>
      <ul class="money-list">
        <li
          v-for="{ id, name, desc, selected } in payList"
          :key="id"
          @click="selectedPayType(id, selected)"
        >
          <span class="left">
            <em :class="['icon', id]"></em>
            {{ name }}
          </span>
          <span class="right">
            {{ desc }}
            <em class="select-icon" :class="{ selected }"></em>
          </span>
        </li>
      </ul>
      <template v-if="productInfo.mibiBalance === 0">
        <p class="line"></p>
        <div class="mibi">
          <em class="mibi-icon"></em>
          <div>
            <p class="mibi-title">{{ $t('common.mibi') }}</p>
            <p class="mibi-count">{{ $tc('common.accuout', 0) }}</p>
          </div>
        </div>
      </template>
    </div>
    <mi-button @click="paymentHandler">
      <em class="pay-icon"></em>
      {{ btnText }}
    </mi-button>
  </div>
</template>

<script>
import { checkPrivacy } from '@/mixins'
import { MiButton } from '@/components'
import { unitFormat, zhCN } from '@/utils'
import { payNameConfig } from '@/config'
import { sub, add } from '@lzwme/asmd-calc'

export default {
  mixins: [checkPrivacy],
  components: {
    MiButton
  },
  data() {
    const { tradeId, userId } = this.$route.query

    return {
      userId,
      tradeId,
      payType: 'mibi',
      showAll: false,
      payList: [],
      productInfo: {},
      market: ''
    }
  },
  computed: {
    // 展示通用礼券
    showUseGiftcard() {
      const {
        productInfo: { useGiftcard, giftcardAvailableValue }
      } = this

      return useGiftcard && zhCN && giftcardAvailableValue > 0
    },
    // 展示专用礼券
    showUseMarketCard() {
      const {
        productInfo: { useMarketGiftcard, marketGiftcardAvailableValue }
      } = this

      return useMarketGiftcard && marketGiftcardAvailableValue > 0
    },
    // 展示卡券列表
    visibleCardList() {
      const { showUseGiftcard, showUseMarketCard } = this

      return showUseGiftcard || showUseMarketCard
    },
    // 米币总量
    totalMiBalance() {
      const {
        mibiBalance,
        giftcardAvailableValue,
        marketGiftcardAvailableValue
      } = this.productInfo

      return add(
        mibiBalance,
        giftcardAvailableValue,
        marketGiftcardAvailableValue
      )
    },
    // 扣减额度
    deductAmount() {
      const {
        price,
        selectMibiBalance,
        mibiBalance,
        selectGiftCard,
        giftcardAvailableValue,
        selectMarketCard,
        marketGiftcardAvailableValue
      } = this.productInfo
      const selectAmount = add(
        selectGiftCard ? giftcardAvailableValue : 0,
        selectMarketCard ? marketGiftcardAvailableValue : 0,
        selectMibiBalance ? mibiBalance : 0
      )
      const temp = sub(selectAmount - price)

      return Math.abs(temp >= 0 ? price : selectAmount)
    },
    // 还需支付额度
    chargeFee() {
      const {
        productInfo: { price },
        deductAmount
      } = this
      const other = sub(price, deductAmount)

      return other > 0 ? other : 0
    },
    selectMibi: {
      get() {
        const {
          showUseGiftcard,
          showUseMarketCard,
          productInfo: { selectGiftCard, selectMarketCard, selectMibiBalance }
        } = this

        return (
          (showUseGiftcard && selectGiftCard) ||
          (showUseMarketCard && selectMarketCard) ||
          selectMibiBalance
        )
      },
      set(val) {
        ;['selectGiftCard', 'selectMarketCard', 'selectMibiBalance'].forEach(
          key => {
            this.productInfo[key] = val
          }
        )
      }
    },
    btnText() {
      const { chargeFee } = this

      return chargeFee > 0
        ? this.$tc('payment.btn', chargeFee)
        : this.$t('payment.confirm')
    }
  },
  created() {
    this.mibiPayInfo()

    MiFiHybrid.invoke('UI', 'requestInterceptBackAndHomeKeyEvent', false)

    if (MiFiHybrid.isApp()) {
      this.backPress()
    }
  },
  methods: {
    backPress() {
      window.onBackPressed = () => {
        this.$messageBox({
          cancel: this.$t('payment.picker.confirm'),
          confirm: this.$t('payment.picker.cancel'),
          content: this.$t('payment.picker.desc')
        }).catch(() => {
          MiFiHybrid.invoke('Navigator', 'closePage')
        })

        return true
      }

      // 拦截返回
      MiFiHybrid.invoke('UI', 'requestInterceptBackAndHomeKeyEvent', true)
    },
    async mibiPayInfo() {
      const {
        userId,
        tradeId,
        $i18n: { locale }
      } = this
      const payName = payNameConfig[locale]
      const res = await this.$http.mibiPayInfo({
        userId,
        tradeId
      })

      if (res) {
        if (res.returnUrl) {
          this.$router.replace(res.returnUrl.substr(3))
          return
        }

        const {
          chargeTypes: { up = [] },
          marketingDescription
        } = res

        // 格式化金额
        ;[
          'price',
          'mibiBalance',
          'giftcardAvailableValue',
          'marketGiftcardAvailableValue'
        ].forEach(key => (res[key] = unitFormat(res[key])))

        // 控制卡券选中字段
        ;['selectGiftCard', 'selectMarketCard', 'selectMibiBalance'].forEach(
          key => (res[key] = true)
        )

        this.market = res.market
        this.productInfo = res
        this.payList = up.map(id => ({
          id,
          selected: false,
          name: payName[id],
          desc: marketingDescription[id]
        }))
      } else {
        this.$router.push('/')
      }
    },
    selectCard(key) {
      this.productInfo[key] = !this.productInfo[key]
    },
    selectedPayType(id, selected) {
      const { chargeFee } = this

      this.payList.forEach(v => (v.selected = false))
      this.payList.find(v => v.id === id).selected = !selected

      if (selected) {
        this.payType = ''
      } else {
        this.payType = id
        if (chargeFee === 0) {
          this.selectMibi = false
        }
      }
    },
    validate() {
      const { payType, chargeFee, selectMibi } = this

      return new Promise((resolve, reject) => {
        if (!zhCN && chargeFee > 0 && chargeFee < 30) {
          reject(this.$t('payment.warn'))
        }
        if ((chargeFee > 0 && payType) || (selectMibi && chargeFee === 0)) {
          resolve()
        }

        reject(this.$t('message.warn'))
      })
    },
    async paymentHandler() {
      try {
        await this.validate()

        const {
          payType,
          tradeId,
          chargeFee,
          market: from,
          productInfo: { selectGiftCard, selectMarketCard }
        } = this
        const data = {
          from,
          payType,
          tradeId,
          chargeFee,
          useGiftcard: selectGiftCard,
          useMarketGiftcard: selectMarketCard
        }

        this.$http.mibiPay(data)
      } catch (e) {
        this.$toast(e)
      }
    }
  },
  beforeDestroy() {
    MiFiHybrid.invoke('UI', 'requestInterceptBackAndHomeKeyEvent', false)
  },
  watch: {
    chargeFee(val) {
      const { payType } = this

      if (val > 0) {
        if (payType === 'mibi') {
          this.payType = 'wxpay'
        }

        if (!zhCN) {
          this.payType = 'paypal'
        }

        this.payList.find(v => v.id === this.payType).selected = true
      } else {
        this.payType = 'mibi'
        this.payList.forEach(v => (v.selected = false))
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./style/index.scss"></style>
